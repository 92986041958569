import DateUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
// import 'normalize.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'reflect-metadata';
import { App, PrivateRoute } from './App';
import { SigninPage } from './components/Auth/SigninPage';
import { CouponAddPage } from './components/Coupons/CouponAddPage';
import { CouponEditPage } from './components/Coupons/CouponEditPage';
import { CouponListPage } from './components/Coupons/CouponListPage';
import { EmailRegListPage } from './components/EmailReg/EmailRegListPage';
import { HostProfileAddPage } from './components/Hosts/HostProfileAddPage';
import { HostProfileEditPage } from './components/Hosts/HostProfileEditPage';
import { HostProfileListPage } from './components/Hosts/HostProfileListPage';
import { LabPage } from './components/lab/LabPage';
import { Notfound } from './components/NotFound';
import { ReservationsListPage } from './components/Reservations/ReservationsListPage';
import { SuppliersProfileEditPage } from './components/Supplier/SuppliersProfileEditPage';
import { TripAddPage } from './components/Trips/TripAddPage';
import { TripsListPage } from './components/Trips/TripsListPage';
import { TripTabsPage } from './components/Trips/TripTabsPage';
import { BasePage } from './components/TripSidebar';
import './styles/main.scss';
import { Theme } from './Theme';
import { AuthContextProvider } from './utils/AuthContext';

const routing = (
  <ThemeProvider theme={Theme}>
    <CssBaseline />
    <AuthContextProvider>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <MuiPickersUtilsProvider utils={DateUtils}>
          <Router>
            <BasePage>
              <Switch>
                <Route exact path="/" component={App} />
                <Route path="/signin" component={SigninPage} />
                {/*<Route path="/signup-user" component={SignupUserPage} />*/}
                {/*<Route path="/signup-supplier" component={SignupSupplierPage} />*/}
                <PrivateRoute path="/trips/add" component={TripAddPage} />
                <PrivateRoute path="/trips/:tripId/:tabPath" component={TripTabsPage} />
                <PrivateRoute path="/trips" component={TripsListPage} />

                <PrivateRoute path="/hosts/add" component={HostProfileAddPage} />
                <PrivateRoute path="/hosts/:hostId" component={HostProfileEditPage} />
                <PrivateRoute path="/hosts" component={HostProfileListPage} />

                <PrivateRoute path="/coupons/add" component={CouponAddPage} />
                <PrivateRoute path="/coupons/edit/:couponId" component={CouponEditPage} />
                <PrivateRoute path="/coupons" component={CouponListPage} />

                <PrivateRoute path="/supplier" component={SuppliersProfileEditPage} />
                <PrivateRoute path="/emailreg" component={EmailRegListPage} />
                <PrivateRoute path="/reservations" component={ReservationsListPage} />
                <PrivateRoute path="/lab" component={LabPage} />
                <Route component={Notfound} />
              </Switch>
            </BasePage>
          </Router>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </AuthContextProvider>
  </ThemeProvider>
);

ReactDOM.render(routing, document.getElementById('root'));
