import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, IconButton, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import OpenInNew from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TripWithVersion } from '@tripr/common';
import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Configuration } from '../../api/Config';
import { GetImageUrl } from '../../api/ImagesApi';
import { TripWithVersionApi } from '../../api/RpcClient';
import { LoadingGuard } from '../common/LoadingGuard';
import { useMenu } from '../common/WithMenu';

export const TripItem: React.FC<{ trip: TripWithVersion }> = ({ trip }) => {
  const history = useHistory();
  const onClick = () => {
    history.push(`/trips/${trip.tripId.toString()}/edit`);
  };
  const [showMenu, renderMenu] = useMenu({
    options: [
      { label: 'Open inventory', onClick: () => history.push(`/trips/${trip.tripId.toString()}/inventory`) },
      {
        label: 'Copy',
        onClick: () => {
          TripWithVersionApi.cloneTripWithVersion(trip.tripId).then(tripId => {
            history.push(`/trips/${tripId.toString()}/edit`);
          });
        },
      },
      {
        label: 'Delete',
        onClick: () => {
          TripWithVersionApi.deleteTrip(trip.tripId).then(() => {
            history.go(0);
          });
        },
      },
    ],
  });

  return (
    <Card>
      <CardActionArea onClick={onClick}>
        {trip.currentVersion.coverImage && <CardMedia component="img" height="240" image={GetImageUrl(trip.currentVersion.coverImage, 480, 280)} />}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {trip.currentVersion.title}
          </Typography>
          {trip.active && (
            <Typography variant="body2" color="textSecondary" component="p">
              ${trip.currentVersion.price}
            </Typography>
          )}
          {!trip.active && (
            <Typography variant="body1" color="error" component="p">
              Trip is not active
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button onClick={onClick}>Edit trip</Button>
        <Link
          target="_blank"
          href={`${Configuration.ConsumersUrl}/details/${trip.currentVersion.slug}`}
          color="inherit"
          variant="button"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <OpenInNew style={{ fontSize: 16 }} />
          &nbsp;PREVIEW
        </Link>
        <IconButton style={{ marginLeft: 'auto' }} onClick={showMenu}>
          <MoreVertIcon />
        </IconButton>
        {renderMenu()}
      </CardActions>
    </Card>
  );
};

export const TripsList: React.FC<{ trips: TripWithVersion[] }> = ({ trips }) => {
  const history = useHistory();
  const [showInactive, setShowInactive] = useState(true);
  const [showDeleted, setShowDeleted] = useState(false);

  let filteredTrips = showInactive ? trips : trips.filter(t => t.active);
  filteredTrips = showDeleted ? trips : trips.filter(t => !t.deleted);
  const sortedTrips = _.sortBy(filteredTrips, t => t.currentVersion.title);
  return (
    <div>
      <Typography gutterBottom variant="h2">
        Trips
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Button variant="contained" color="primary" onClick={() => history.push(`/trips/add`)}>
            Add trip
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <Switch onChange={() => setShowInactive(!showInactive)} checked={showInactive} />
          inactive
        </Grid>
        <Grid item xs={2}>
          <Switch onChange={() => setShowDeleted(!showDeleted)} checked={showDeleted} />
          deleted
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {sortedTrips.map(trip => (
          <Grid key={trip.tripId} item xs={6} sm={4}>
            <TripItem key={trip.tripId} trip={trip} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export const TripsListPage: React.FC = () => (
  <LoadingGuard loadData={() => TripWithVersionApi.getWithVersionList()}>{trips => <TripsList trips={trips} />}</LoadingGuard>
);
