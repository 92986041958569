import { Box, BoxProps, CircularProgress, createStyles, IconButton, InputBase, makeStyles, Theme, Typography } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { UploadedImage } from '@tripr/common';
import React, { MouseEventHandler } from 'react';
import { GetImageUrl } from '../../api/ImagesApi';

export interface ImageInputValue {
  jobId: string;
  status: 'empty' | 'in_progress' | 'finished' | 'error';
  file?: File;
  image: UploadedImage | null;
  error?: string;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    parent: {
      cursor: 'pointer',
    },
    inProgressImg: {
      opacity: 0.5,
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
);

export interface ImageInputViewProps {
  size: [number, number];
  value: ImageInputValue;
  onRemove?: MouseEventHandler;
  isDragActive?: boolean;
  boxProps?: BoxProps;
  onDescriptionChange?(jobId: string, value: string): void;
}

export const ImageInputView: React.FC<ImageInputViewProps> = ({ value, size, onRemove, isDragActive, boxProps, children, onDescriptionChange }) => {
  const classes = useStyles();
  const status = 'status' in value ? value.status : 'finished';
  const imgUrl = value.file ? URL.createObjectURL(value.file) : value.image ? GetImageUrl(value.image, size[0], size[1]) : null;
  const opacue = status === 'in_progress' || status === 'error' || isDragActive;
  return (
    <Box
      borderRadius={10}
      overflow="hidden"
      bgcolor={isDragActive ? green[100] : 'grey.200'}
      width={1}
      padding={1}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'stretch'}
      {...boxProps}
      className={classes.parent}
      style={{ outline: 'none' }}
    >
      <Box flex={0} position="relative">
        <Box width={120} height={120} borderRadius={10} overflow="hidden" className={opacue ? classes.inProgressImg : ''}>
          {imgUrl && <img alt={''} src={imgUrl} className={classes.img} />}
        </Box>
        {children}
        <Box width={1} height={1} position="absolute" display="flex" justifyContent="center" alignItems="center">
          {isDragActive && <Typography color="textPrimary">Drop here</Typography>}
          {!isDragActive && status === 'in_progress' && <CircularProgress />}
          {!isDragActive && status === 'error' && <Typography color="error">Error {value.error}</Typography>}
          {!isDragActive && status === 'empty' && <Typography color="textPrimary">Click or drop</Typography>}
        </Box>
        {status !== 'empty' && status !== 'in_progress' && onRemove && (
          <Box position="absolute" right={5} top={5}>
            <IconButton size="small" onClick={onRemove}>
              <RemoveCircleIcon fontSize="inherit" />
            </IconButton>
          </Box>
        )}
      </Box>
      {value.image && (
        <Box flex={1} marginLeft={1}>
          <InputBase
            multiline
            fullWidth
            placeholder={'Image description'}
            onChange={e => (onDescriptionChange ? onDescriptionChange(value.jobId, e.target.value) : null)}
            value={value.image.description}
            onClick={e => {
              e.stopPropagation();
            }}
            style={{
              height: '100%',
              borderRadius: 10,
              backgroundColor: 'white',
              padding: 8,
            }}
            inputProps={{ style: { height: '100%' } }}
          />
        </Box>
      )}
    </Box>
  );
};
