import { Box, FormControl, FormHelperText, Typography } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import { generateRandomId, Image, UploadedImage } from '@tripr/common';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useField } from 'react-final-form';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { moveInArray, notEmpty, useOnce } from '../../utils/Utils';
import { ImageInputValue, ImageInputView, ImageInputViewProps } from './ImageInputView';

const DraggableImage = SortableElement((props: ImageInputViewProps) => <ImageInputView {...props} />);

const DraggableImagesContainer = SortableContainer(({ children }: { children: React.ReactNode }) => (
  <Box display={'flex'} flexWrap="wrap">
    {children}
  </Box>
));

interface Props {
  name: string;
  size: [number, number];
  uploader(file: File): Promise<UploadedImage>;
}

export const MultiImageUploader = (props: Props) => {
  const { input, meta } = useField<Image[] | 'UPLOADING'>(props.name, {
    validate: val => (val === 'UPLOADING' ? 'Some images are still uploading' : undefined),
  });
  const value = input.value;

  const [jobs, setJobs] = useState<ImageInputValue[]>([]);

  useOnce(() => {
    if (value && value !== 'UPLOADING') {
      setJobs(value.map(v => ({ jobId: generateRandomId(10), status: 'finished', image: v })));
    }
  });

  const setValue = (newJobs: ImageInputValue[] | 'UPLOADING') => {
    if (newJobs === 'UPLOADING') {
      input.onChange('UPLOADING');
    } else if (newJobs.every(j => j.status === 'finished')) {
      const newValues: UploadedImage[] = newJobs.map(f => (f.status === 'finished' ? f.image : null)).filter(notEmpty);
      input.onChange(newValues);
    }
  };

  const startUploadingImage = (file: File) => {
    props
      .uploader(file)
      .then(image => {
        setJobs(oldJobs => {
          const newJobs = oldJobs.map(
            (j): ImageInputValue => (j.status === 'in_progress' && j.file === file ? { jobId: j.jobId, status: 'finished', file, image } : j),
          );
          setValue(newJobs);
          return newJobs;
        });
      })
      .catch(e => {
        setJobs(oldJobs => {
          const newJobs = oldJobs.map(
            (j): ImageInputValue =>
              j.status === 'in_progress' && j.file === file ? { jobId: j.jobId, status: 'error', file, image: null, error: JSON.stringify(e) } : j,
          );
          setValue(newJobs);
          return newJobs;
        });
      });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setValue('UPLOADING');
    const newJobs = acceptedFiles.map((file): ImageInputValue => {
      startUploadingImage(file);
      return {
        jobId: generateRandomId(10),
        status: 'in_progress',
        image: null,
        file,
      };
    });
    setJobs(oldJobs => oldJobs.concat(newJobs));
    input.onBlur();
  }, []);

  const onDescriptionChange = (jobId: string, description: string) => {
    setJobs(oldJobs => {
      const newJobs = oldJobs.map((j): ImageInputValue => (j.jobId === jobId && j.image ? { ...j, image: { ...j.image, description } } : j));
      setValue(newJobs);
      return newJobs;
    });
  };

  const onRemove = (job: ImageInputValue) => {
    setJobs(oldJobs => {
      const newJobs = oldJobs.filter(j => j.jobId !== job.jobId);
      setValue(newJobs);
      return newJobs;
    });
  };

  const onReorder = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setJobs(oldJobs => {
      const newJobs = moveInArray(oldJobs, oldIndex, newIndex);
      setValue(newJobs);
      return newJobs;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <FormControl error={showError} fullWidth>
      <Box p={1} borderRadius={10} bgcolor={'grey.100'}>
        <DraggableImagesContainer onSortEnd={onReorder} distance={2} axis="y">
          {jobs.map((job, i) => (
            <DraggableImage
              index={i}
              size={props.size}
              key={job.jobId}
              value={job}
              onDescriptionChange={onDescriptionChange}
              onRemove={() => onRemove(job)}
              boxProps={{ m: 1 }}
            />
          ))}
        </DraggableImagesContainer>
        <Box {...getRootProps()} borderRadius={10} bgcolor={isDragActive ? green[100] : 'grey.100'} p={5} style={{ outline: 'none' }}>
          <input {...getInputProps()} />
          <Typography align={'center'}>{isDragActive ? 'Drop the files here ...' : "Drag 'n' drop some files here, or click to select files"}</Typography>
        </Box>
      </Box>
      {showError && <FormHelperText>{JSON.stringify(meta.error || meta.submitError)}</FormHelperText>}
    </FormControl>
  );
};
