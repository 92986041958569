import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ReviewId, TripId } from '@tripr/common';
import React from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import { InventoryListTable } from '../Inventory/InventoryListTable';
import { ReviewAddPage } from '../Reviews/ReviewAddPage';
import { ReviewEditPage } from '../Reviews/ReviewEditPage';
import { ReviewListTable } from '../Reviews/ReviewListTable';
import { TripEditPage } from './TripEditPage';
import { useAuthContext } from '../../utils/AuthContext';

export const TripTabsPage: React.FC = () => {
  const params = useParams<{ tripId: TripId; tabPath: string }>();
  const allTabs = [`/trips/${params.tripId}/edit`, `/trips/${params.tripId}/inventory`, `/trips/${params.tripId}/reviews`];
  const authContext = useAuthContext();

  return (
    <>
      <Tabs value={params.tabPath} style={{ marginBottom: 16 }}>
        <Tab label="Edit" value={`edit`} component={Link} to={allTabs[0]} />
        <Tab label="Inventory" value="inventory" component={Link} to={allTabs[1]} />
        {authContext.isLoggedIn && authContext.account.accountType === 'admin' && <Tab label="Reviews" value="reviews" component={Link} to={allTabs[2]} />}
      </Tabs>
      <Switch>
        <Route path={allTabs[0]} render={() => <TripEditPage tripId={params.tripId} />} />
        <Route path={allTabs[1]} render={() => <InventoryListTable tripId={params.tripId} />} />
        <Route path={`${allTabs[2]}/add`} render={() => <ReviewAddPage tripId={params.tripId} />} />
        <Route path={`${allTabs[2]}/:reviewId`} render={p => <ReviewEditPage tripId={params.tripId} reviewId={ReviewId(p.match.params.reviewId)} />} />
        <Route path={allTabs[2]} render={() => <ReviewListTable tripId={params.tripId} />} />
      </Switch>
    </>
  );
};
