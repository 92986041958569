import { FormControl, FormHelperText, Grid, GridSize, InputLabel, Typography } from '@material-ui/core';
import { fillPricePerPerson, formatPerPerson, Money, PricePerPerson, PricePerPersonCalculated } from '@tripr/common';
import * as React from 'react';
import { NumberField } from './TextField';
import { FieldRenderProps } from 'react-final-form';

export const PricePerPersonItem = ({
  pricePerPerson,
  pricePerPersonCalculated,
  index,
}: {
  pricePerPerson: PricePerPerson;
  pricePerPersonCalculated: PricePerPersonCalculated;
  index: number;
}) => (
  <div style={{ minWidth: 60, marginRight: 10, display: 'flex', justifyContent: 'space-between' }}>
    {formatPerPerson(index)}:
    <Typography variant="body2" component={'span'} color={pricePerPerson[index] === null ? 'textSecondary' : 'inherit'}>
      ${pricePerPerson[index] || pricePerPersonCalculated[index]}
    </Typography>
  </div>
);

export const PricePerPersonDisplay = ({
  tripPrice,
  pricePerPerson,
  tripPricePerPerson,
}: {
  tripPrice: Money;
  pricePerPerson: PricePerPerson;
  tripPricePerPerson?: PricePerPerson;
}) => {
  const pricePerPersonCalculated = fillPricePerPerson(tripPrice, pricePerPerson, tripPricePerPerson);
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <PricePerPersonItem pricePerPerson={pricePerPerson} pricePerPersonCalculated={pricePerPersonCalculated} index={0} />
      <PricePerPersonItem pricePerPerson={pricePerPerson} pricePerPersonCalculated={pricePerPersonCalculated} index={1} />
      <PricePerPersonItem pricePerPerson={pricePerPerson} pricePerPersonCalculated={pricePerPersonCalculated} index={2} />
      <PricePerPersonItem pricePerPerson={pricePerPerson} pricePerPersonCalculated={pricePerPersonCalculated} index={3} />
      <PricePerPersonItem pricePerPerson={pricePerPerson} pricePerPersonCalculated={pricePerPersonCalculated} index={4} />
      <PricePerPersonItem pricePerPerson={pricePerPerson} pricePerPersonCalculated={pricePerPersonCalculated} index={5} />
    </div>
  );
};

export const getPricePerPersonField =
  (tripPrice: Money, tripPricePerPerson?: PricePerPerson) =>
  ({ value, onChange }: { value?: PricePerPerson; onChange(value?: PricePerPerson): void }) => {
    const placeholders = fillPricePerPerson(tripPrice, value, tripPricePerPerson);

    const priceChange = (i: number) => (v: number) => {
      const newValue = value || [null, null, null, null, null, null];
      newValue[i] = Money(v);
      onChange(newValue);
    };

    return (
      <div style={{ display: 'flex' }}>
        {placeholders.map((p, i) => (
          <div key={i} style={{ minWidth: 60, marginRight: 10 }}>
            {formatPerPerson(i)}: <NumberField onChange={priceChange(i)} value={value ? value[i] : undefined} placeholder={p.toString(10)} />
          </div>
        ))}
      </div>
    );
  };

export const MyPricePerPersonField: React.FC<FieldRenderProps<PricePerPerson> & { xs: GridSize }> = ({
  input: { onChange, value },
  meta,
  label,
  formControlProps,
  xs,
}) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  const placeholders = fillPricePerPerson(Money(0), value);

  const priceChange = (i: number) => (v: number) => {
    const newValue = value ? [...value] : [null, null, null, null, null, null];
    newValue[i] = Money(v);
    onChange(newValue);
  };

  return (
    <Grid item xs={xs}>
      <FormControl {...formControlProps} error={showError} fullWidth>
        <InputLabel shrink>{label}</InputLabel>

        <div style={{ display: 'flex', marginTop: 20 }}>
          {placeholders.map((p, i) => (
            <div key={i} style={{ minWidth: 60, marginRight: 10 }}>
              {formatPerPerson(i)}: <NumberField onChange={priceChange(i)} value={value ? value[i] : undefined} placeholder={p.toString(10)} />
            </div>
          ))}
        </div>

        {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};
