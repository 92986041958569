import { Box, Typography } from '@material-ui/core';
import { arrayInsert, Inventory, TripId, TripWithVersion } from '@tripr/common';
import { format, parse } from 'date-fns';
import MaterialTable, { MTableBodyRow } from 'material-table';
import React, { useState } from 'react';
import { InventoryApi, TripWithVersionApi } from '../../api/RpcClient';
import { useToast } from '../../utils/Utils';
import { DateStringTableField } from '../common/forms/DateField';
import { getPricePerPersonField, PricePerPersonDisplay } from '../common/forms/PricePerPersonField';
import { NumberField } from '../common/forms/TextField';
import { LoadingGuard } from '../common/LoadingGuard';

export const InventoryListTableInner: React.FC<{ trip: TripWithVersion; inventoryItems: Inventory[]; onReload(): void }> = props => {
  const [inventoryItems, setInventoryItems] = useState(props.inventoryItems);
  const [callWithToast] = useToast('inventory');
  const trip = props.trip;

  return (
    <div>
      <MaterialTable
        columns={[
          {
            title: 'Date',
            field: 'tripDate',
            render: d => (
              <div style={{ width: 150 }}>
                {d.tripDate ? format(parse(d.tripDate, 'yyyy-MM-dd', 0), 'do MMMM yyyy') : <Typography color={'error'}>Date missing</Typography>}
              </div>
            ),
            editComponent: DateStringTableField,
          },
          {
            title: 'Spots',
            field: 'tripSpots',
            type: 'numeric',
            initialEditValue: trip.currentVersion.maxPeople,
            editComponent: NumberField,
          },
          {
            title: 'Booked',
            field: 'tripSpotsBooked',
            type: 'numeric',
            initialEditValue: 0,
            editComponent: NumberField,
          },
          {
            title: 'Price Per Person',
            field: 'pricePerPerson',
            render: v => (
              <PricePerPersonDisplay
                tripPrice={trip.currentVersion.price}
                pricePerPerson={v.pricePerPerson}
                tripPricePerPerson={trip.currentVersion.pricePerPerson}
              />
            ),
            editComponent: getPricePerPersonField(trip.currentVersion.price, trip.currentVersion.pricePerPerson),
          },
        ]}
        data={inventoryItems}
        title={trip.currentVersion.title}
        actions={[
          {
            icon: 'content_copy',
            tooltip: 'Copy Row',
            onClick: (_, rowData) => {
              if (!Array.isArray(rowData)) {
                const inventory = { ...rowData, inventoryId: undefined, tripDate: undefined } as any as Inventory;
                setInventoryItems(arrayInsert(inventoryItems, inventory, inventoryItems.indexOf(rowData) + 1));
              }
            },
          },
        ]}
        editable={{
          onRowAdd: async newData => {
            await callWithToast(InventoryApi.createInventory({ ...newData, tripId: trip.tripId }));
            props.onReload();
          },
          onRowUpdate: async newData => {
            if (newData.inventoryId) {
              await callWithToast(InventoryApi.updateInventory(newData));
            } else {
              await callWithToast(InventoryApi.createInventory(newData));
            }
            props.onReload();
          },
          onRowDelete: async oldData => {
            await callWithToast(InventoryApi.deleteInventory(oldData.inventoryId));
            props.onReload();
          },
        }}
        components={{
          Row: props => (
            <MTableBodyRow
              {...props}
              onDoubleClick={(e: any) => {
                // 2 - 3rd action for edit
                props.actions[2]().onClick(e, props.data);
              }}
            />
          ),
        }}
        options={{
          paging: false,
          search: false,
          actionsColumnIndex: -1,
        }}
      />
      <Box my={2} mx={1}>
        <Typography color={'textSecondary'}>Hint: double click on a table row to edit it.</Typography>
      </Box>
    </div>
  );
};

export const InventoryListTable: React.FC<{ tripId: TripId }> = props => {
  const [reload, setReload] = useState(0);

  return (
    <LoadingGuard
      key={reload}
      loadData={async () => {
        const trip = await TripWithVersionApi.getWithVersion(props.tripId);
        const inventoryItems = await InventoryApi.getInventoryList(props.tripId);
        return { trip, inventoryItems };
      }}
    >
      {({ trip, inventoryItems }) => (
        <InventoryListTableInner
          trip={trip}
          inventoryItems={inventoryItems}
          onReload={() => {
            setReload(reload + 1);
          }}
        />
      )}
    </LoadingGuard>
  );
};
