import { Typography } from '@material-ui/core';
import { Coupon } from '@tripr/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CouponApi, TripWithVersionApi } from '../../api/RpcClient';
import { useAuthContext } from '../../utils/AuthContext';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { CouponForm, CouponValues } from './CouponForm';

export const CouponAddPage: React.FC = () => {
  const history = useHistory();
  const [callWithToast] = useToast('coupon');
  const authContext = useAuthContext();
  if (!authContext.isLoggedIn || authContext.account.accountType !== 'admin') {
    return null;
  }

  return (
    <LoadingGuard
      loadData={async () => {
        const tripOptions = await TripWithVersionApi.getTripOptions();
        return { tripOptions };
      }}
    >
      {({ tripOptions }) => (
        <div>
          <Typography gutterBottom variant="h2">
            Add coupon
          </Typography>
          <CouponForm
            tripOptions={tripOptions}
            onSubmit={async (values: CouponValues) => {
              const newCoupon: Coupon = values;

              const couponId = await callWithToast(CouponApi.createCoupon(newCoupon));
              history.push(`/coupons/edit/${couponId.toString()}`);
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
