import { Typography } from '@material-ui/core';
import { Trip, TripId, TripVersion } from '@tripr/common';
import React from 'react';
import { HostApi, TripWithVersionApi } from '../../api/RpcClient';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { TripForm, TripValues } from './components/TripForm';

export const TripEditPage: React.FC<{ tripId: TripId }> = props => {
  const [callWithToast] = useToast('trip');

  return (
    <LoadingGuard
      loadData={async () => {
        const hosts = await HostApi.getHostOptions();
        const trip = await TripWithVersionApi.getWithVersion(TripId(props.tripId));
        const tripOptions = await TripWithVersionApi.getTripOptions();
        return { hosts, trip, tripOptions };
      }}
    >
      {({ hosts, trip, tripOptions }) => (
        <div>
          <Typography gutterBottom variant="h3">
            {trip.currentVersion.title}
          </Typography>
          <TripForm
            trip={{
              ...trip.currentVersion,
              active: trip.active,
              featured: trip.featured,
              featuredPosition: trip.featuredPosition,
              deleted: trip.deleted,
              hostProfileId: trip.hostProfileId,
              createdById: trip.createdById,
            }}
            hosts={hosts}
            tripOptions={tripOptions}
            onSubmit={async (values: TripValues) => {
              const { hostProfileId, createdById, active, featured, deleted, featuredPosition, ...tripVersionValues } = values;
              const { currentVersion, ...tripFields } = trip;
              const newTrip: Trip = {
                ...tripFields,
                active,
                deleted,
                featured,
                featuredPosition,
                hostProfileId,
                createdById,
              };

              const tripVersion: TripVersion = {
                ...tripVersionValues,
                tripId: trip.tripId,
                tripVersionId: trip.currentVersion.tripVersionId,
              };

              await callWithToast(TripWithVersionApi.updateTripWithVersion(newTrip, tripVersion));
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
