import { Box, Button, IconButton, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ReorderIcon from '@material-ui/icons/Reorder';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => (
  <Box p={1} style={{ cursor: 'grab' }}>
    <ReorderIcon />
  </Box>
));

const FormListItem = SortableElement(({ children, onRemove }: { children: React.ReactNode; onRemove(): void }) => (
  <div>
    <Paper>
      <Box display={'flex'} alignItems={'start'} mb={2}>
        <Box flex={1} py={2} pl={2}>
          {children}
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems="center">
          <IconButton color="secondary" onClick={onRemove}>
            <RemoveCircleIcon />
          </IconButton>
          <DragHandle />
        </Box>
      </Box>
    </Paper>
    {/* <Box display="flex" justifyContent="center" mb={2}>
              <Button color="default" onClick={() => fields.insert(index, newValue())}>
                insert here
              </Button>
            </Box> */}
  </div>
));

const FormListContainer = SortableContainer(({ children }: { children: React.ReactNode }) => <div>{children}</div>);

interface Props<T> {
  name: string;
  newValue(): T;
  render(props: { parentName: string }): React.ReactNode;
}

export const FormList = <T extends Record<string, any>>({ name, render, newValue }: Props<T>) => (
  <FieldArray
    name={name}
    subscription={{}}
    render={({ fields }) => (
      <div>
        <FormListContainer onSortEnd={({ oldIndex, newIndex }) => fields.move(oldIndex, newIndex)} useDragHandle>
          {fields.map((parentName, index) => (
            <FormListItem index={index} key={index.toString(10)} onRemove={() => fields.remove(index)}>
              {render({ parentName })}
            </FormListItem>
          ))}
        </FormListContainer>

        <Box>
          <Button variant="contained" color="default" startIcon={<AddIcon />} onClick={() => fields.push(newValue())}>
            Add
          </Button>
        </Box>
      </div>
    )}
  />
);
