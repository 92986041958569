import { Box, Grid, Typography } from '@material-ui/core';
import {
  Accommodation,
  AccommodationType,
  Currency,
  DifficultyLevel,
  ExperienceLevel,
  Faq,
  HostId,
  IsoCountryCode,
  isoCountryCodeList,
  isoCountryMap,
  Itinerary,
  MaybeMoney,
  Money,
  PetAllowance,
  PricePerPerson,
  RecommendedTrip,
  TourType,
  TripId,
  TripType,
  UploadedImage,
  WhatIsIncluded,
  WhatIsIncludedDefault,
} from '@tripr/common';
import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { NumberSchema, Schema, StringSchema } from 'yup';
import { ImageArray, OptionalImage } from '../../../api/CommonSchemas';
import { ImagesApi } from '../../../api/ImagesApi';
import { useAuthContext } from '../../../utils/AuthContext';
import { MySelect } from '../../common/forms/MySelect';
import { MyPricePerPersonField } from '../../common/forms/PricePerPersonField';
import { BooleanField, MyTextField } from '../../common/forms/TextField';
import { yupValidate } from '../../common/forms/YupValidate';
import { MultiImageUploader } from '../../common/MultiImageUploader';
import { MyImageInput } from '../../common/SingleImageUploader';
import { SubmitButton } from '../../common/SubmitButton';
import { AccommodationList } from './AccomodationList';
import { FaqList } from './FaqList';
import { ItineraryList } from './ItenaryList';
import { WhatIsIncludedField } from './WhatIsIncludedField';
import { RecommendedTripList } from './RecommendedTripList';

export const TripSchema = yup.object({
  active: yup.boolean(),
  featured: yup.boolean(),
  deleted: yup.boolean(),
  featuredPosition: yup.number(),
  tripType: yup.string().required().oneOf(Object.keys(TripType)) as StringSchema<TripType>,
  hostProfileId: yup.string().nullable() as StringSchema<HostId | null>,
  createdById: yup.string().nullable() as StringSchema<HostId | null>,
  title: yup.string().required(),
  slug: yup
    .string()
    .required()
    .matches(/^[a-z0-9_-]+$/gi, 'Slug can contain only letters, digits, underscore (_) or dash (-)'),
  description: yup.string().required(),
  country: yup.string().required().oneOf(isoCountryCodeList, 'Country required') as StringSchema<IsoCountryCode>,
  tourType: yup.string().required().oneOf(Object.keys(TourType)) as StringSchema<TourType>,
  difficulty: yup.string().required().oneOf(Object.keys(DifficultyLevel)) as StringSchema<DifficultyLevel>,
  experience: yup.string().required().oneOf(Object.keys(ExperienceLevel)) as StringSchema<ExperienceLevel>,
  accommodationType: yup.string().required().oneOf(Object.keys(AccommodationType)) as StringSchema<AccommodationType>,
  destination: yup.string().required(),
  days: yup.number().required().min(1),
  maxPeople: yup.number().required().min(1),
  price: yup.number().required().min(1) as NumberSchema<Money>,
  pricePerPerson: yup
    .array<MaybeMoney>()
    .default([null, null, null, null, null, null])
    .of(yup.number().nullable().min(1) as NumberSchema<Money>) as any as Schema<PricePerPerson>,
  currency: yup.string().oneOf(Object.keys(Currency)).default('USD') as StringSchema<Currency>,
  itineraryOnlyPrice: yup.number().nullable().min(1) as NumberSchema<Money | null>,
  itineraryOnlyEnabled: yup.boolean(),
  starRating: yup.number().required().min(1).max(5),
  reviews: yup.number().required(),
  overview: yup.string().required(),
  whatIsIncluded: yup.object<WhatIsIncluded>().required().noUnknown().default(WhatIsIncludedDefault).shape({
    allMealsIncluded: yup.boolean().required(),
    alcoholIncluded: yup.boolean().required(),
    gratuitiesIncluded: yup.boolean().required(),
    transfersIncluded: yup.boolean().required(),
    flightsIncluded: yup.boolean().required(),
    accommodationIncluded: yup.boolean().required(),
    admissionFeesIncluded: yup.boolean().required(),
    porterIncluded: yup.boolean().required(),
    visasIncluded: yup.boolean().required(),
    travelInsuranceIncluded: yup.boolean().required(),
    personalGearIncluded: yup.boolean().required(),
    guideFeesIncluded: yup.boolean().required(),
    luggageTransfersIncluded: yup.boolean().required(),
  }),
  itinerary: yup
    .array<Itinerary>()
    .default([])
    .of(
      yup.object().shape({
        day: yup.number().min(1).required(),
        headline: yup.string().required(),
        description: yup.string().required(),
      }),
    ),
  accommodations: yup
    .array<Accommodation>()
    .default([])
    .of(
      yup.object().shape({
        dayStart: yup.number().min(1).required(),
        numberOfDays: yup.number().min(1).required(),
        accommodationType: yup.string().notRequired().oneOf(Object.keys(AccommodationType)) as StringSchema<AccommodationType | undefined>,
        description: yup.string().required(),
        photos: ImageArray,
        petAllowance: yup.string().notRequired().oneOf(Object.keys(PetAllowance)) as StringSchema<PetAllowance | undefined>,
      }),
    ),
  faq: yup
    .array<Faq>()
    .default([])
    .of(
      yup.object().shape({
        question: yup.string().required(),
        answer: yup.string().required(),
      }),
    ),
  coverImage: OptionalImage,
  gallery: ImageArray,
  recommendedTrips: yup
    .array<RecommendedTrip>()
    .default([])
    .of(
      yup.object().shape({
        tripId: yup.string().required() as StringSchema<TripId>,
      }),
    ),
});

export type TripValues = yup.InferType<typeof TripSchema>;
const validator = yupValidate(TripSchema);

const InitialValues: TripValues = TripSchema.cast();

const uploadTripImg = async (file: File): Promise<UploadedImage> => new ImagesApi().uploadImage('trip', file);

export const TripForm: React.FC<{
  trip?: TripValues;
  hosts: { [key: string]: string };
  tripOptions: { [key: string]: string };
  onSubmit(trip: TripValues): Promise<void>;
}> = ({ trip, hosts, tripOptions, onSubmit }) => {
  const initialValues = trip || InitialValues;
  const authContext = useAuthContext();

  return (
    <div>
      <Form<TripValues>
        initialValues={initialValues}
        validate={validator}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        // subscription={{ submitting: true, pristine: true }}
        subscription={{}}
        onSubmit={values => onSubmit(TripSchema.cast(values))}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box maxWidth={640}>
              <Grid container spacing={3}>
                {authContext.isLoggedIn && authContext.account.accountType === 'admin' && (
                  <>
                    <Field component={BooleanField} name="active" label="Active" xs={2} />
                    <Field component={BooleanField} name="featured" label="Featured" xs={2} />
                    <Field component={MyTextField} name="featuredPosition" label="Featured Position" xs={3} type="number" />
                    <Field component={BooleanField} name="deleted" label="Deleted" xs={2} />
                    <Field component={MySelect} name="tripType" label="Tour Type" xs={3} options={TripType} />
                  </>
                )}
                <Field component={MyTextField} name="title" label="Title" xs={12} />
                <Field component={MyTextField} name="slug" label="Slug" xs={9} />
                <Field component={MySelect} name="tripType" label="Tour Type" xs={3} options={TripType} />

                <Field component={MySelect} name="country" label="Country" xs={6} options={isoCountryMap} />
                <Field component={MyTextField} name="destination" label="Destination" xs={6} />

                <Field component={MySelect} name="tourType" label="Tour Type" xs={3} options={TourType} />
                <Field component={MySelect} name="difficulty" label="Difficulty" xs={3} options={DifficultyLevel} />
                <Field component={MySelect} name="experience" label="Experience" xs={3} options={ExperienceLevel} />
                <Field component={MySelect} name="accommodationType" label="Accommodation" xs={3} options={AccommodationType} />

                <Field component={MyTextField} name="description" label="Description" xs={12} multiline />

                <Field component={MyTextField} name="days" label="Days" xs={3} type="number" />
                <Field component={MyTextField} name="maxPeople" label="Max People" xs={3} type="number" />
                <Field component={MyTextField} name="price" label="Price" xs={3} type="number" />
                <Field component={MySelect} name="currency" label="Currency" xs={3} options={Currency} />

                <Field component={MyPricePerPersonField} name="pricePerPerson" label="Price per person" xs={12} />

                <Field component={MyTextField} name="starRating" label="Star Rating" xs={3} type="number" />
                <Field component={MyTextField} name="reviews" label="Reviews" xs={3} type="number" />
                <Field component={BooleanField} name="itineraryOnlyEnabled" label="Itinerary Only" xs={3} />
                <Field component={MyTextField} name="itineraryOnlyPrice" label="Itinerary Only Price" xs={3} type="number" />

                <Grid item xs={12} />
                <Field component={MySelect} name="hostProfileId" label="Guide" xs={6} options={hosts} nullable />
                <Field component={MySelect} name="createdById" label="Created By" xs={6} options={hosts} nullable />

                <Field component={MyTextField} name="overview" label="Overview" xs={12} multiline />

                <Grid item xs={12}>
                  <Typography variant={'h4'}>Gallery</Typography>
                  <MyImageInput name={'coverImage'} uploader={uploadTripImg} size={[480, 280]} />
                </Grid>
                <Grid item xs={12}>
                  <MultiImageUploader name={'gallery'} uploader={uploadTripImg} size={[480, 280]} />
                </Grid>

                <Field component={WhatIsIncludedField} label="Pricing" name="whatIsIncluded" xs={3} />

                <Grid item xs={12}>
                  <Typography variant={'h4'}>Itinerary</Typography>
                  <ItineraryList name="itinerary" />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'h4'}>Accommodations</Typography>
                  <AccommodationList name="accommodations" />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'h4'}>FAQ</Typography>
                  <FaqList name="faq" />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'h4'}>Recommended Trips</Typography>
                  <RecommendedTripList name="recommendedTrips" tripOptions={tripOptions} />
                </Grid>

                <SubmitButton caption="Save trip" handleSubmit={handleSubmit} />
              </Grid>
            </Box>
          </form>
        )}
      />
    </div>
  );
};
