import { Typography } from '@material-ui/core';
import { Trip } from '@tripr/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HostApi, TripWithVersionApi } from '../../api/RpcClient';
import { useAuthContext } from '../../utils/AuthContext';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { TripForm, TripSchema, TripValues } from './components/TripForm';

export const TripAddPage: React.FC = () => {
  const history = useHistory();
  const [callWithToast] = useToast('trip');
  const authContext = useAuthContext();
  const supplierProfileId = authContext.isLoggedIn ? authContext.account.supplierProfileId : undefined;
  if (!supplierProfileId) {
    return null;
  }
  return (
    <LoadingGuard
      loadData={async () => {
        const hosts = await HostApi.getHostOptions();
        const tripOptions = await TripWithVersionApi.getTripOptions();
        return { hosts, tripOptions };
      }}
    >
      {({ hosts, tripOptions }) => (
        <div>
          <Typography gutterBottom variant="h2">
            Add new trip
          </Typography>
          <TripForm
            hosts={hosts}
            tripOptions={tripOptions}
            onSubmit={async (values: TripValues) => {
              const { hostProfileId, createdById, active, featured, deleted, featuredPosition, ...tripVersionValues } = TripSchema.cast(values);
              const trip: Omit<Trip, 'tripId'> = {
                supplierProfileId,
                hostProfileId,
                createdById,
                active: false,
                featured: featured ?? false,
                featuredPosition: featuredPosition ?? 0,
                deleted: deleted ?? false,
              };
              const tripId = await callWithToast(TripWithVersionApi.createTripWithVersion(trip, tripVersionValues));
              history.push(`/trips/${tripId.toString()}`);
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
