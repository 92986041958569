import { Checkbox, FormControlLabel, FormHelperText, Grid, Typography } from '@material-ui/core';
import { WhatIsIncluded } from '@tripr/common';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

const amenitiesList: { name: keyof WhatIsIncluded; text: string }[] = [
  { name: 'allMealsIncluded', text: 'All meals' },
  { name: 'transfersIncluded', text: 'Transfers' },
  { name: 'alcoholIncluded', text: 'Alcoholic Beverages' },
  { name: 'flightsIncluded', text: 'Flights' },
  { name: 'gratuitiesIncluded', text: 'Gratuities' },
  { name: 'accommodationIncluded', text: 'Accommodation' },
  { name: 'admissionFeesIncluded', text: 'Admission fees' },
  { name: 'porterIncluded', text: 'Porter' },
  { name: 'visasIncluded', text: 'Visas' },
  { name: 'travelInsuranceIncluded', text: 'Travel insurance' },
  { name: 'personalGearIncluded', text: 'Personal gear' },
  { name: 'guideFeesIncluded', text: 'Guide fees' },
  { name: 'luggageTransfersIncluded', text: 'Luggage transfers' },
];

export const WhatIsIncludedField: React.FC<FieldRenderProps<WhatIsIncluded>> = ({ input: { name, value, onChange }, meta, label }) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <Grid item xs={12}>
      {/*<FormControl {...formControlProps} error={showError} fullWidth>*/}
      <Typography variant={'h4'}>{label}</Typography>
      {amenitiesList.map(a => (
        <FormControlLabel
          key={a.name}
          control={
            <Checkbox
              checked={value[a.name]}
              onChange={v => {
                onChange({ ...value, [a.name]: v.target.checked });
              }}
              name={`${name}.${a.name}`}
            />
          }
          label={a.text}
        />
      ))}
      {showError && <FormHelperText>{JSON.stringify(meta.error || meta.submitError)}</FormHelperText>}
      {/*</FormControl>*/}
    </Grid>
  );
};
